import React from "react";
import PageHeader from "../components/page-header";
import Seo from "../components/seo";
import Layout from "../layouts/index";

const CookiePolicy = () => {
  return (
    <Layout>
      <Seo title="Cookie Policy" />

      <PageHeader title="Cookie Policy" />

      <div class="grid-container block">
        <p>
          MotoNovo Finance is committed to protecting you and any data (anonymous or otherwise) that we collect about
          you online. This web page explains how we use cookies, why, and how this allows us to improve our services. It
          also tells you how you can manage what cookies are stored on your device. We call it our Cookie Policy.
        </p>

        <p>
          By using our websites (through any device) you agree that our Cookie Policy applies, in addition to any other
          terms and conditions which may apply.
        </p>

        <p>
          We reserve the right to make changes to our Cookie Policy. Any changes will appear here and become
          <br />
          effective immediately. Your continued use of our websites is taken as meaning that you agree to such changes.
          <br />
          <br />
        </p>
        <h4>What are cookies?</h4>

        <p>
          Cookies are files containing small amounts of information which are downloaded to the device you use when you
          visit a website. These can then be read by that website on subsequent visits or by other websites that
          recognise that cookie. Cookies do lots of different and useful jobs, such as remembering your preferences and
          generally improving your online experience.
        </p>
        <p>There are different types of cookies. We have highlighted some of these differences below:</p>

        <p>
          <strong>Session or Persistent Cookies</strong>
        </p>
        <p>All cookies are either session or persistent cookies.</p>
        <ul>
          <li>
            <strong>Session cookies</strong>&nbsp;last only for the duration of your visit and are deleted when you
            close your browser. They facilitate tasks such as allowing a website to identify that a user of a particular
            device is navigating from page to page, supporting website security or basic functionality. Our session
            cookies used for security are designed to be very difficult to read, change, access or use, except by us.
            They contain no personal information that can be used to identify an individual.
          </li>
          <li>
            <strong>Persistent cookies</strong>&nbsp;last after you have closed your browser and allow a website to
            remember your actions and preferences. MotoNovo Finance uses persistent cookies in a few ways, for example,
            to allow us to analyse customer visits to our site. These cookies help us to understand how users arrive at,
            and use, our site so we can improve our service. Sometimes persistent cookies are used by websites to
            provide targeted advertising based on the browsing history of the devices.
          </li>
        </ul>
        <p>
          <strong>First- and Third-Party Cookies</strong>
        </p>
        <ul>
          <li>
            <strong>First party cookies</strong>&nbsp;are those set by or on behalf of the MotoNovo Finance website
            visited. These enable the website to run at an optimum level and enhance the user experience. They are also
            used to keep your preferences.
          </li>
          <li>
            <strong>Third party cookies</strong>&nbsp;are those which are set up by external companies or vendors. These
            cookies are likely to be performance and targeting cookies, including analytics cookies to enable us to
            monitor and optimise our website.
          </li>
        </ul>
        <p></p>
        <h4>Cookies we use</h4>
        <p>
          In order to access our websites, we ask you to interact with our cookie banner to ensure that you provide
          consent to cookies we use by either accepting all cookies or managing your preferences. You cannot interact
          with the website until you have accepted or personalised your cookie preferences. This change was made to
          comply with new ICO guidelines that require active user consent to cookies, avoiding the use of default
          settings as was previously the case. You can personalise which categories of cookies you wish to consent to
          through the ‘Privacy preference centre’ available by clicking ‘Cookie settings’:
        </p>
        <ul>
          <li>
            <strong>Strictly necessary cookies:</strong>&nbsp;These cookies are essential to enable you to move around
            the website and use its features, and for ensuring the security of your online journey. Without these
            cookies, services such as applying for products and managing your accounts can not be provided.
          </li>
          <li>
            <strong>Functional cookies:</strong>&nbsp;These cookies may be used to provide services you request, such as
            the ability to speak to our customer services team through live chat. The information these cookies collect
            is anonymised (i.e. it does not contain your name, address, account details, etc.).&nbsp;
          </li>
          <li>
            <strong>Site performance &amp; analytics cookies:</strong>&nbsp;These cookies collect information about how
            visitors use a website, for example, which pages visitors go to most often, and if they get error messages
            from web pages. These cookies do not collect information that identifies a visitor, although they may
            collect the IP address of the device used to access the site. All information these cookies collect is
            anonymous and is only used to improve how our websites work and the user experience.
          </li>
          <li>
            <strong>Targeted marketing &amp; profiling cookies:</strong>&nbsp;We use these cookies to help us promote
            our services to people we believe are most interested in them, based on the way they interact with our
            website. They remember that you have visited a website and this information is shared with other
            organisations that host or place promotions, such as media publishers. These organisations do this in order
            to help us make sure our advertising is shown to people who are more likely to find it interesting and
            useful. This type of advertising is called online behavioural advertising and places an icon in the top
            right-hand corner of an advert.&nbsp;
          </li>
          <li>
            <strong>Social media cookies:</strong>&nbsp;We use these cookies to share our feeds with you on our webpage
            and allow you to share content with your friends and networks and use them to track visits to our website.
            If you do not allow these cookies you may not be able to use or see these tools.
          </li>
        </ul>
        <p>
          If you would like to know more about the cookies MotoNovo Finance uses, please click on ‘Cookie settings’ on
          the website that you were visiting before viewing this policy (located in the left-hand side of the webpage
          footer). From there, you can access that website’s unique ‘Privacy preference centre’ to see full details of
          the cookies used within that site.
        </p>
        <p>
          By using our websites, you agree that we can place these types of cookies on your device, however, you can
          block them by following the methods outlined below.&nbsp;
        </p>
        <p>
          <strong>Email&nbsp;</strong>
        </p>
        <p>
          We sometimes track how you interact with our emails. This is to help us understand if emails have been opened
          and if links within the mail have been clicked.&nbsp;
          <br />
          <br />
        </p>
        <h4>What if I don’t want to accept Cookies?</h4>
        <p>
          <strong>Website Cookies:</strong>&nbsp;You can block or enable cookies by categories within our ‘Privacy
          preference centre’, either by clicking ‘Cookie settings’ on the banner at the top of the page or by clicking
          the ‘Cookie settings’ button on the website that you were visiting before viewing this policy (located in the
          left-hand side of each webpage’s footer). We regularly scan our websites to maintain an up-to-date list of
          cookies used to keep it as accurate as possible.
        </p>
        <p>
          You can also block or restrict cookies set by any website – including MotoNovo Finance websites – through your
          browser settings.
        </p>
        <p>
          <strong>Email:</strong>&nbsp;if you would prefer to restrict tracking within emails, you can either set this
          up within your browser or email settings.&nbsp;
        </p>
        <p></p>
        <h4>Personalising</h4>
        <p>
          By switching cookies off (with the exception of Strictly necessary cookies which are always enabled) you will
          lose some functionality including: live person webchats, social media feeds &amp; the ability to share on
          social media, the ability to read and write reviews.
        </p>
        <p>
          If you opt into any cookie categories and subsequently change your mind, you may see that the cookies remain
          on your device until they expire. We will no longer access these cookies if you have opted out of categories
          and these will be removed over time. If you clear your cookies entirely, you will find that you will need to
          provide your consent the next time you visit our website.
        </p>
        <p>
          Please be aware that&nbsp;some of our services, for example our portals, will not function if your browser
          does not accept cookies. However, you can allow cookies from specific websites by making them ‘trusted
          websites’ in your internet browser.
        </p>
        <p>
          For information on how to do this on a wider variety of browsers go to&nbsp;
          <strong>
            <a href="http://www.allaboutcookies.org/" rel="noreferrer">
              All About Cookies
            </a>
          </strong>
          .&nbsp;
        </p>
      </div>
    </Layout>
  );
};
export default CookiePolicy;
